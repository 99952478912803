/* Основные стили страницы */
.link-nfc-page {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 100px 20px;
    background-color: #f5f7fa;
    min-height: 100vh; /* Высота страницы на весь экран */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Центрируем контент */
    align-items: center;
}

/* Контейнер с блоком информации */
.nfc-container {
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    width: 100%;
    text-align: center;
    flex-grow: 1; /* Контейнер займет доступное пространство */
    margin-bottom: 40px;
}

/* Заголовок */
.nfc-container h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

/* Описание процесса */
.nfc-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

/* Кнопка сканирования */
.scan-nfc-button {
    padding: 12px 20px;
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    width: 100%;
    max-width: 250px;
}

.scan-nfc-button:hover {
    background-color: #e64a4a;
    transform: translateY(-2px);
}

/* Отображение считанного тега */
.nfc-tag-id {
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
    padding: 10px;
    background: #eef2f7;
    border-radius: 8px;
    display: inline-block;
    width: fit-content;
}




/* Медиа-запросы для адаптивности */
@media (max-width: 600px) {
    .nfc-container {
        padding: 20px;
        max-width: 90%;
    }
    
    .scan-nfc-button {
        font-size: 0.9rem;
    }
}

/* AboutClub.css */

/* Переменные CSS */
:root {
    --primary-color: #ff6f61; /* Основной цвет */
    --secondary-color: #f5f7fa; /* Второстепенный цвет */
    --text-color: #333;
    --background-color: #fff;
    --accent-color: #ff4e42;
    --font-family: 'Open Sans', sans-serif;
}

/* Сброс стилей */
* {
    box-sizing: border-box;
}

body {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
}

/* Общий стиль секции */
.about-club-section {
    padding: 60px 20px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

/* Контейнер для секции */
.about-club-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

/* Стили для заголовков */
.about-club-section h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: var(--text-color);
    position: relative;
}

.about-club-section > .about-club-content h2::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--accent-color);
    margin-top: 10px;
}

/* Колонка "Читаем сейчас" */
.current-book-section {
    background-color: var(--background-color);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    flex: 1; /* Занимает 1 часть от общего пространства */
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.current-book-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Центрирование по вертикали */
    width: 100%;
    
}

.book-description {
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.6;
}

/* Колонка "О клубе" */
.about-club-info {
    background-color: var(--background-color);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    flex: 2; /* Занимает 2 части от общего пространства */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.club-description {
    font-size: 16px;
    line-height: 1.8;
    color: var(--text-color);
    flex-grow: 1;
}

/* Адаптивность для планшетов и телефонов */
@media (max-width: 991px) {
    .about-club-content {
        flex-direction: column;
        align-items: center; /* Центрируем элементы по горизонтали */
    }

    .current-book-section,
    .about-club-info {
        width: 100%; /* Элементы занимают 100% ширины */
        margin-bottom: 20px;
        flex: none; /* Убираем флекс-пропорции */
    }

    /* Убираем разные значения flex у колонок */
    .current-book-section {
        flex: none;
    }

    .about-club-info {
        flex: none;
    }
}

/* Адаптивность для телефонов */
@media (max-width: 575px) {
    .about-club-section {
        padding: 40px 15px;
    }

    .about-club-section h2 {
        font-size: 24px;
    }

    .book-cover {
        max-width: 200px;
    }

    .book-details h3 {
        font-size: 20px;
    }

    .author-name {
        font-size: 16px;
    }

    .book-description,
    .club-description {
        font-size: 14px;
    }

    .button-standard {
        width: 100%;
        text-align: center;
    }
}

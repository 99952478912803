.scan-page-wrapper {
    /* Этот контейнер растягивается на всю высоту экрана */
    min-height: 100vh;      
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

/* ScanTagPage.css */
.scan-tag-page {
    /* Занимает всё доступное пространство, чтобы футер 
     можно было прижать к низу */
    flex: 1 0 auto;
    padding: 20px;
    min-height: 60vh; /* чтобы страница не схлопывалась */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .scan-block {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  }
  
  .scan-block h2 {
    margin-bottom: 10px;
  }
  
  .scan-block p {
    margin-bottom: 15px;
  }
  
  .btn-group {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .btn {
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  /* Пример цветовых стилей для кнопок */
  .btn-primary {
    background-color: #123456; /* фирменный цвет */
    color: #fff;
  }
  
  .btn-primary:hover {
    background-color: #0f2b45;
  }
  
  .btn-secondary {
    background-color: #FCAF37; /* другой фирменный цвет */
    color: #fff;
  }
  
  .btn-secondary:hover {
    background-color: #e0a52f;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  .btn-danger:hover {
    background-color: #c62f3e;
  }
  
  /* Стили для лоадера */
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .spinner {
    width: 48px;
    height: 48px;
    border: 6px solid #eee;
    border-top: 6px solid #123456;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Примеры разных блоков в зависимости от состояния */
  .auth-required-block {
    border-left: 4px solid #FCAF37;
  }
  .unlinked-block {
    border-left: 4px solid #123456;
  }
  .linked-block {
    border-left: 4px solid #0f2b45;
  }
  .active-session-block {
    border-left: 4px solid green;
  }
  .blocked-block {
    border-left: 4px solid gold;
  }
  .error-block {
    border-left: 4px solid red;
  }
  
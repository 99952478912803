/* EventHighlight.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Полная высота экрана */
  padding: 40px;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #2c3e50, #ff6f61);
}

.event-title {
  font-size: 4rem;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.event-subtitle {
  font-size: 1.8rem;
  margin-bottom: 30px;
  font-family: 'Arial', sans-serif;
  font-weight: 300;
  text-align: justify;
  max-width: 800px;
}

.event-details {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 600px;
  margin-bottom: 30px;
}

.event-detail-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.event-detail-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.event-detail-value {
  font-size: 1.5rem;
}

.event-button {
  margin-top: 30px;
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 30px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.event-button:hover {
  background-color: #ffffff;
  color: #ff6f61;
}

@media (max-width: 768px) {
  .event-title {
    font-size: 3rem;
  }

  .event-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .event-details {
    width: 80%;
  }

  .event-detail-label,
  .event-detail-value {
    font-size: 1.2rem;
  }

  .event-button {
    padding: 12px 24px;
    font-size: 1rem;
  }
}

/* src/components/profile/HeroProfileSection.css */

.hero-profile {
  padding: 80px 20px;
  background: linear-gradient(135deg, #2c3e50, #ff6f61);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-profile__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.hero-profile__text {
  flex: 1;
  color: #fff;
}

.hero-profile__name {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.hero-profile__city {
  font-size: 1.5rem;
  opacity: 0.8;
}

.hero-profile__avatar {
  flex: 0 0 250px;
  width: 250px;
  height: 250px;
  margin-left: 40px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.hero-profile__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .hero-profile__content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .hero-profile__text {
    margin-top: 20px;
  }

  .hero-profile__avatar {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .hero-profile__name {
    font-size: 2.5rem;
  }

  .hero-profile__city {
    font-size: 1.2rem;
  }
}

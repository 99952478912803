/* Общие стили для страницы профиля */
.profile-page {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.loading {
  text-align: center;
  padding: 50px;
  font-size: 1.5rem;
}

/* Стили для кнопки редактирования профиля */
.edit-profile-button-container {
  text-align: center;
  margin: 30px 0;
}

.edit-profile-button {
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.3);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.edit-profile-button:hover {
  background-color: #ff5252;
  transform: translateY(-3px);
}
.link-nfc-button {
  padding: 0.75rem 1.5rem;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.link-nfc-button:hover {
  background-color: #45a049;
}

/* Медиазапросы для адаптивности */
@media (max-width: 768px) {
  .edit-profile-button {
    width: 80%;
    padding: 12px 0;
  }
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-image) center center / cover no-repeat;
  width: 100%;
  min-height: 100vh;
  color: #fff;
  text-align: center;
  transition: background-image 1s ease-in-out;
  padding: 60px 20px;
  box-sizing: border-box;
}

.hero-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.hero-section__content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.hero-section__content h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero-section__content p {
  font-size: 1.5rem;
  margin-bottom: 40px;
  line-height: 1.5;
}

.hero-section__button {
  display: inline-block;
  background-color: #ff6f61;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-section__button:hover {
  background-color: #ff4e42;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .hero-section {
    padding: 80px 20px;
    min-height: 60vh;
  }

  .hero-section__content h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .hero-section__content p {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .hero-section__button {
    font-size: 1rem;
    padding: 12px 25px;
  }
}

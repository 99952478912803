.footer {
  background-color: #f8f9fa;
  color: #343a40;
  padding: 20px 0;
  border-top: 1px solid #e9ecef;
  width: 100%;
  box-sizing: border-box;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
}

.footer-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.footer-logo .logo-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.footer-logo .colored-logo {
  display: flex;
  font-size: 1.5rem;
}

.footer-logo .black-text {
  color: #000;
}

.footer-logo .red-text {
  color: #ff6f61;
}

.footer-social {
  display: flex;
  gap: 20px;
}

.footer-social a img {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.footer-social a:hover img {
  transform: scale(1.2);
}

.footer-copyright {
  font-size: 0.875rem;
  color: #6c757d;
  text-align: right;
}

.footer-copyright p {
  margin: 0;
}

/* Адаптивность */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer-logo .logo-image {
    width: 40px;
    height: 40px;
  }

  .footer-logo .colored-logo {
    font-size: 1.25rem;
  }

  .footer-social {
    order: 3; /* Перемещаем социальные иконки ниже */
  }

  .footer-copyright {
    text-align: center;
    order: 2; /* Перемещаем авторское право после логотипа */
  }
}

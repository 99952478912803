/* src/components/auth/Register.css */

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f7fa;
  margin-top: 50px;
}

.register-form-container {
  display: flex;
  max-width: 900px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Предотвращаем выход контента за границы */
}

.register-form {
  padding: 40px;
  flex: 1;
}

.register-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.register-input-group {
  margin-bottom: 20px;
}

.register-input-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 8px;
  font-weight: 600;
}

.register-input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 50px;
  font-size: 1rem;
}

.register-input-group input::placeholder {
  color: #9ca3af;
}

.register-button {
  width: 100%;
  padding: 12px;
  background-color: #ff4e42;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #ff4e42;
}

.register-image {
  flex: 1;
  border-radius: 0 8px 8px 0;
  overflow: hidden;
}

.register-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
}

/* === Адаптивность для экранов до 768px === */
@media (max-width: 768px) {
  .register-form-container {
    flex-direction: column; /* Переключаем на вертикальное направление */
    max-width: 90%;
  }

  .register-form {
    padding: 20px;
  }

  .register-image {
    display: none; /* Скрываем изображение на маленьких экранах */
  }

  .register-title {
    font-size: 1.5rem;
  }

  .register-input-group input {
    font-size: 0.9rem;
    padding: 8px;
  }

  .register-button {
    font-size: 0.9rem;
    padding: 10px;
  }
}

/* === Адаптивность для экранов до 480px === */
@media (max-width: 480px) {
  .register-form-container {
    width: 95%;
  }

  .register-title {
    font-size: 1.2rem;
  }

  .register-form {
    padding: 10px;
  }

  .register-input-group label {
    font-size: 0.8rem;
  }

  .register-input-group input {
    font-size: 0.8rem;
    padding: 8px;
  }

  .register-button {
    font-size: 0.8rem;
    padding: 8px;
  }
}

/* src/components/Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f8fc;
    margin-top: 50px; /* Добавляем отступ сверху */
  }
  
  .login-form-container {
    display: flex;
    width: 80%;
    max-width: 1000px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .login-form {
    flex: 1;
    padding: 40px;
  }
  
  .login-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f8fc;
  }
  
  .login-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .login-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #111827;
  }
  
  .login-subtitle {
    margin-bottom: 30px;
    color: #6b7280;
  }
  
  .login-subtitle a {
    color: #6366f1;
  }
  
  .login-input-group {
    margin-bottom: 20px;
  }
  
  .login-input-group label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 8px;
    color: #4b5563;
  }
  
  .login-input-group input {
    width: 100%;
    padding: 12px;
    border-radius: 50px;
    border: 1px solid #e5e7eb;
    font-size: 1rem;
    color: #374151;
    
  }
  
  .login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .login-remember-me {
    display: flex;
    align-items: center;
  }
  
  .login-remember-me input {
    margin-right: 8px;
    
  }
  
  .login-forgot-password {
    color: #6366f1;
    text-decoration: none;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #ff4e42;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .login-button:hover {
    background-color: #ff4e42;
  }
  
  .login-or {
    text-align: center;
    color: #9ca3af;
    margin-bottom: 20px;
  }
  
  .login-social-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .social-button {
    flex: 1;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #e5e7eb;
  }
  
  .telegram-button {
    background-color: #0088cc;
    color: white;
    margin-right: 10px;
  }
  
  .vk-button {
    background-color: #4c75a3;
    color: white;
  }
  
  .social-button i {
    margin-right: 8px;
  }

  /* Адаптивность для экранов до 768px */
@media (max-width: 768px) {
  .login-form-container {
    flex-direction: column; /* Смена направления с горизонтального на вертикальное */
    width: 90%;
  }

  .login-form {
    padding: 20px; /* Уменьшаем отступы */
  }

  .login-image {
    display: none; /* Скрываем изображение на маленьких экранах */
  }

  .login-title {
    font-size: 1.5rem;
  }

  .login-input-group input {
    font-size: 0.9rem;
  }

  .login-button {
    font-size: 0.9rem;
    padding: 10px;
  }
}

/* Адаптивность для экранов до 480px */
@media (max-width: 480px) {
  .login-title {
    font-size: 1.2rem;
  }

  .login-form {
    padding: 10px; /* Ещё меньше отступов */
  }

  .login-input-group label {
    font-size: 0.8rem;
  }

  .login-input-group input {
    font-size: 0.8rem;
    padding: 10px;
  }

  .login-button {
    font-size: 0.8rem;
    padding: 8px;
  }

  .social-button {
    font-size: 0.8rem;
    padding: 10px;
  }
}

  
/* Общий стиль страницы */
.event-details-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
}

/* Герой-секция */
.hero-section-event {
  position: relative;
  width: 100%;
  height: 100vh; /* Фиксированная высота для изображения */
  background-image: var(--backgroundImage);
  background-size: cover;
  background-position: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-overlay h1 {
  color: #fff;
  font-size: 4rem;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-overlay p {
  color: #fff;
  font-size: 2rem;
}

.scroll-down {
  margin-top: 20px;
  display: inline-block;
  animation: pulse 2s infinite;
}

.scroll-down:hover svg path {
  fill: #ff6f61;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Информация о событии */
.event-info {
  max-width: 1000px;
  width: 100%;
  margin: 20px auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Лёгкая тень */
}

.event-description {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2.2;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Детали события */
.event-details {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.event-details .detail-item {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 0;
  border-bottom: 1px solid #ddd; /* Разделительные линии */
}

.event-details .detail-item h2 {
  font-size: 1.6rem;
  color: #ff6f61;
  text-align: center;
  margin-bottom: 10px;
}

.event-details .detail-item p,
.event-details .detail-item ul,
.event-details .detail-item ol {
  font-size: 1.2rem;
  line-height: 1.8; /* Увеличенная высота строк */
  text-align: justify;
  margin: 10px 0;
}

.event-details .detail-item ul li,
.event-details .detail-item ol li {
  margin-bottom: 10px;
}

/* Галерея изображений */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.image-gallery img {
  width: 40%;
  margin: 10px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень для изображений */
  border: 2px solid #ddd; /* Тонкая рамка */
}

/* Кнопка регистрации */
.event__button {
  display: inline-block;
  background-color: #ff6f61;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Тень для кнопки */
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.event__button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(255, 111, 97, 0.5);
  background-color: #ff4e42; /* Более яркий цвет при наведении */
}

/* Адаптивный дизайн */
@media (max-width: 768px) {
  .hero-overlay h1 {
    font-size: 2.5rem;
  }

  .hero-overlay p {
    font-size: 1.2rem;
  }

  .event-description p,
  .event-details .detail-item p,
  .event-details .detail-item ul,
  .event-details .detail-item ol {
    font-size: 1rem;
    line-height: 1.6;
  }

  .image-gallery img {
    width: 90%;
  }

  .event__button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

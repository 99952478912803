.about-us-section {
  margin: 60px 0;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f9f9f9;
  box-sizing: border-box; /* Добавлено для корректных отступов */
}

.about-us-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  gap: 40px;
}

.about-us-item__text {
  flex: 1;
  padding-right: 10px;
  text-align: left;
}

.about-us-item__text h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.highlight-mission {
  color: #ff6f61;
}

.about-us-item__text p {
  font-size: 1.1rem;
  line-height: 1.8;
}

.about-us-item__image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-item__image-container img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Адаптивность */
@media (max-width: 768px) {
  .about-us-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-us-item__text {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .about-us-item__text h2 {
    font-size: 2rem;
  }

  .about-us-item__text p {
    font-size: 1rem;
  }

  .about-us-item__image-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-us-item__image-container img {
    width: 100%;
    height: auto;
  }
}

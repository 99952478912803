/* Общие стили */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

/* Логотип */
.navbar__logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar__logo .logo-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.colored-logo span:first-child {
  color: #000;
  font-size: 1.8rem;
  font-weight: bold;
}

.colored-logo span:last-child {
  color: #ff6f61;
  font-size: 1.8rem;
  font-weight: bold;
}

/* Кнопки на широком экране */
.navbar__actions {
  display: flex;
  gap: 15px;
}

.navbar__actions .navbar__button {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding: 10px 20px;
  border-radius: 50px;
  color: #fff;
  background-color: #ff6f61;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.navbar__actions .navbar__button:hover {
  background-color: #e65a50;
}

.navbar__button--secondary {
  background-color: #fff !important;
  color: #ff6f61 !important;
  border: 1px solid #ff6f61 !important;
}

.navbar__button--secondary:hover {
  background-color: #ffe5e0;
}

/* Иконка гамбургера */
.navbar__toggle {
  display: none;
  cursor: pointer;
}

.navbar__toggle .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #000;
  transition: all 0.3s ease;
}

/* Мобильное меню */
.navbar__menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.navbar__close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

.navbar__links {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
  padding: 0px 0px;
  margin: 0;
  width: 100%;
}

.navbar__button {
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  padding: 10px 20px;
  border-radius: 50px;
  width: 90%;
  max-width: 300px;
  color: #fff;
  background-color: #ff6f61;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Узкий экран */
@media (max-width: 768px) {
  /* .navbar {
    padding: 30px 30px; 
  } */

  .navbar__actions {
    display: none; /* Скрываем кнопки на узких экранах */
  }

  .navbar__toggle {
    display: block;
  }
}

.offers-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 60px 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.offers-header h1 {
  font-size: 2.5rem;
  font-weight: 800;
  color: #333;
  margin-bottom: 40px;
}

.offers-header p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 50px;
  max-width: 600px;
  margin: 0 auto 50px;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-items: center;
  box-sizing: border-box;
}

.offer-card {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.offer-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-icon img {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.card-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #555;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 20px;
  flex-grow: 1;
}

/* Адаптивность для экранов меньше 768px */
@media (max-width: 768px) {
  .offers-section {
    padding: 40px 20px;
  }

  .offers-header h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .offers-header p {
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .card-icon img {
    width: 50px;
    height: 50px;
  }

  .card-title {
    font-size: 1.2rem;
  }

  .card-text {
    font-size: 0.9rem;
  }

  .offer-card {
    min-height: auto;
  }

  .button-standard {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

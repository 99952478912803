.book-card {
  width: 220px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  margin: 15px;
  text-align: center;
}

.book-card:hover {
  transform: translateY(-10px);
}

.book-card__image-wrapper {
  width: 90%; /* Почти вся ширина карточки */
  height: 80%; /* 80% высоты карточки */
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.book-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-card__content {
  padding: 10px 0;
}

.book-card__title {
  font-size: 1rem;
  font-weight: bold;
  color: #4e4a4a;
  margin-bottom: 5px;
}

.book-card__author {
  font-size: 0.9rem;
  color: #383737;
}

/* Цветовые темы */
.color-1 {
  background-color: #f5f7fa;
}

.color-2 {
  background-color: #eaeef4;
}

.color-3 {
  background-color: #d4dce9;
}

.color-4 {
  background-color: #c3cfe2;
}

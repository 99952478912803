.clubs-catalog {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
}

.clubs-catalog__title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
}

.clubs-catalog__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

.clubs-catalog__button {
  padding: 12px 24px;
  font-size: 1rem;
  background-color: #ff4e42;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 20px;
}

.clubs-catalog__button:hover {
  background-color: #ff6b61;
}

.club-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 320px;
  margin: 20px;
  align-items: center;
}

.club-card:hover {
  transform: translateY(-5px);
}

.club-card__link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.club-card__image-container {
  width: 100%;
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.club-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.club-card__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.club-card__name {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #ff6f61;
  margin-bottom: 10px;
}

.club-card__description {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin-bottom: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.club-card__city,
.club-card__members {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 8px;
}

.club-card__reading-section {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.club-card__reading-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ff4e3d;
}

.club-card__book-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* EventCatalog.css */
.event-catalog-section {
  width: 66.66%;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
}

.event-catalog-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid #ff4e42;
  display: inline-block;
  padding-bottom: 10px;
}

.events-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Центрируем карточки по горизонтали */
  gap: 20px; /* Пространство между карточками */
  padding: 20px 0;
}

.event-card {
  flex: 1 1 300px; /* Карточки занимают ширину 300px и адаптируются */
  max-width: 300px;
  aspect-ratio: 4 / 3;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  box-sizing: border-box;
}


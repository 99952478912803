.testimonials-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 60px 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.testimonial-card {
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.testimonial-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #ff4e42;
}

.testimonial-subtitle {
  font-size: 1rem;
  color: #ff6f61;
  margin-bottom: 15px;
  font-style: italic;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
}

.testimonial-avatar {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  padding: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.testimonial-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.testimonial-nav-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
}

.testimonial-nav-button:hover {
  background-color: #ff4e42;
}

/* Адаптивность */
@media (max-width: 768px) {
  .testimonials-section {
    padding: 40px 20px;
  }

  .testimonials-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .testimonial-card {
    padding: 20px;
  }

  .testimonial-title {
    font-size: 1rem;
  }

  .testimonial-subtitle {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  .testimonial-text {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .testimonial-avatar {
    width: 120px;
    height: 120px;
  }

  .testimonial-nav-button {
    padding: 8px 16px;
    font-size: 1.2rem;
    margin: 0 5px;
  }
}

/* src/components/events/EventCard.css */

.event-card {
    width: 300px;
    height: 300px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 8px;
    overflow: hidden;
  }
  


  .event-card-content {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
  
  .event-card h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .event-card p {
    margin: 5px 0;
  }

  .event-flag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff6347; /* Яркий цвет для флажка */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  



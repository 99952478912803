@tailwind base;
@tailwind components;
@tailwind utilities;

/* Единый стиль кнопокок */
.button-standard {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    background-color: #ff6f61;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none; 
}

.button-standard:hover {
    background-color: #ff4e42;
}

.back-to-clubs-container {
    display: flex;
    justify-content: left;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .back-to-clubs-button-scroll {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff6f61;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
    z-index: 1000; /* Обеспечивает видимость кнопки поверх других элементов */
  }
  
  .back-to-clubs-button-scroll:hover {
    background-color: #ff4e42;
  }
  
  .back-to-clubs-button-scroll:focus {
    outline: none;
  }
  
  



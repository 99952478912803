.books-section {
  width: 66.66%;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0; /* Увеличенный отступ от заголовка */
}

.books-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid #ff4e42;
  display: inline-block;
  padding-bottom: 10px;
}

.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Автоматическое распределение колонок */
  gap: 20px;
  justify-content: center;
}



@media (max-width: 768px) {
  .books-section {
    padding: 40px 10px;
  }

  .books-grid {
    grid-template-columns: 1fr; /* Одноколоночный вид для мобильных устройств */
  }

  .book-card {
    min-height: 250px;
  }

  .book-cover-wrapper {
    width: 100px;
    height: 100px;
  }
}

/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3); /* Slightly darker overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: #ffffff;
    padding: 30px;
    width: 400px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
  }
  
  .modal h2 {
    font-size: 1.5em;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #888888;
    transition: color 0.3s;
  }
  
  .modal-close:hover {
    color: #333333;
  }
  
  .modal input,
  .modal textarea {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1em;
    color: #333333;
  }
  
  .modal input::placeholder,
  .modal textarea::placeholder {
    color: #888888;
  }
  
  .submit-button {
    background: #ff5a5f; /* Matching button color */
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;
    transition: background 0.3s;
  }
  
  .submit-button:hover {
    background: #e04a4d; /* Slightly darker on hover */
  }
  
  .submission-message {
    margin-top: 15px;
    color: green;
    font-weight: bold;
    font-size: 0.9em;
  }
  
  .modal input,
  .modal textarea {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Light shadow to match the design */
  }

  
  .error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 10px;
  }
  